import { Modal as AntModal } from 'antd'
import styled from 'styled-components'

const Modal = styled(AntModal)`
  .ant-modal-content {
    margin-top: 32px;
    // min-height: 600px;
  }
`
export const { confirm, warning, error, info, success } = AntModal

export default Modal
