import './KLCSVViewer.css'

import { Spin, Table } from 'antd'
import { Spacing } from 'common/stylings'
import Stack from 'components/Stack'
import Text from 'components/Typography'
import Papa from 'papaparse'
import React, { useEffect, useState } from 'react'

interface KLCSVViewerProps {
  url: string
  studyName: string
}

interface CsvData {
  [key: string]: any
}

const KLIndivCSVViewer: React.FC<KLCSVViewerProps> = ({ url, studyName }) => {
  const [data, setData] = useState<CsvData[]>([])
  const [columns, setColumns] = useState<
    Array<{ title: string; dataIndex: string; key: string; width?: number }>
  >([])
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [error, setError] = useState<boolean>(false)

  useEffect(() => {
    const fetchCSVData = async () => {
      try {
        setIsLoading(true)
        setError(false)
        const response = await fetch(url)

        if (response.status !== 200) {
          setError(true)
          setIsLoading(false)
          return
        }

        const csvData = await response.text()

        Papa.parse(csvData, {
          header: true,
          dynamicTyping: true,
          complete: (result: any) => {
            const parsedData: CsvData[] = result.data
            const filteredData = parsedData.filter(
              row =>
                row['Study ID'] && row['Study ID'].trim() === studyName.trim(),
            )
            setData(filteredData)

            if (filteredData.length > 0) {
              const cols = [
                {
                  title: 'Study ID',
                  dataIndex: 'Study ID',
                  key: 'Study ID',
                  width: 85,
                },
                {
                  title: 'Report',
                  dataIndex: 'Report',
                  key: 'Report',
                  width: 530,
                },
              ]
              setColumns(cols)
            }

            setIsLoading(false)
          },
        })
      } catch (error) {
        setError(true)
        setIsLoading(false)
      }
    }

    fetchCSVData()
  }, [url, studyName])

  if (error) {
    return null
  }

  return (
    <Stack vertical gutter={Spacing.MEDIUM}>
      <Stack vertical>
        <div className="csv-viewer">
          <Text
            style={{
              fontSize: '18px',
            }}
            fontSize={16}
            fontWeight="bold">
            Study ID: {studyName}
          </Text>
          {isLoading ? (
            <div className="csv-viewer-wrapper">
              <Spin tip="Loading CSV data..." />
            </div>
          ) : (
            <div>
              <Table
                className="csv-viewer"
                dataSource={data}
                columns={columns}
                pagination={false}
                rowKey={(record: any, index: any) =>
                  record['Study ID'] || index.toString()
                }
              />
            </div>
          )}
        </div>
      </Stack>
    </Stack>
  )
}

export default KLIndivCSVViewer
