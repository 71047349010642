import './KLZipFileViewer.css'

import { CopyFilled, CopyTwoTone } from '@ant-design/icons'
import { useAuth0 } from '@auth0/auth0-react'
import { Intent } from '@blueprintjs/core'
import { Button, Select, Spin } from 'antd'
import { Checkbox } from 'antd'
import { Colors } from 'common/colors'
import { Spacing } from 'common/stylings'
import KLIndivCSVViewer from 'components/CSVViewer/KLIndivCSVViewer'
import Stack from 'components/Stack'
import Toaster from 'components/Toaster'
import Text from 'components/Typography'
import dicomParser from 'dicom-parser'
import JSZip from 'jszip'
import { get } from 'lodash'
import React, { useEffect, useRef, useState } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators, Dispatch } from 'redux'
import { getAllDoctors } from 'store/doctor/selectors'
import { RootState } from 'store/rootReducer'
import {
  createLoadingSelector,
  createSuccessSelector,
} from 'store/status/selectors'
import { uploadDicomAsMP4 } from 'store/videoSegments/actionCreators'
import {
  addVideoImportLogFlow,
  editVideoCountFlow,
  uploadDicomAsMP4Flow,
} from 'store/videoSegments/actions'

type Props = KLZipFileViewerProps &
  StoreProps<typeof mapStateToProps, typeof mapDispatchToProps>

interface KLZipFileViewerProps {
  url: string
  video: any
  csvURL: string
}

interface FileItem {
  type: 'file' | 'directory'
  name: string
  path: string
  seriesDescription?: string
  children?: FileItem[]
  selected?: boolean
}

const KLZipFileViewer: React.FC<Props> = ({
  url,
  video,
  uploadDicomAsMP4,
  isUploadingDicomAsMP4,
  csvURL,
  isUploadedDicomAsMP4,
  editVideoCount,
  addVideoImportLog,
  allDoctors,
}) => {
  const [hierarchy, setHierarchy] = useState<FileItem[]>([])
  const [isLoading, setIsLoading] = useState(false)
  const [selectedPaths, setSelectedPaths] = useState<string[]>([])
  const [commonSeriesDescription, setCommonSeriesDescription] =
    useState<string>('')
  const exportButtonSuperAnnotateRef = useRef(false)
  const [superAnnotateLogPayload, setSuperAnnotateLogPayload] = useState<any>(
    [],
  )
  const { user } = useAuth0()

  const getBaseUri = (uri: string) => {
    const lastIndex = uri.lastIndexOf('/')
    return uri.substring(0, lastIndex + 1)
  }

  const toggleDirectorySelection = (path: string) => {
    const baseUri = getBaseUri(video.uri)

    setHierarchy(prev => {
      let newState = false
      const newSelectedPaths: string[] = []
      let commonDescription = ''

      const findNewState = (items: FileItem[]): boolean => {
        for (const item of items) {
          if (item.path === path) {
            newState = !item.selected
            if (item.seriesDescription && !newState) {
              commonDescription = ''
            } else if (item.seriesDescription && newState) {
              commonDescription = item.seriesDescription
            }
            return true
          }
          if (item.children && findNewState(item.children)) {
            return true
          }
        }
        return false
      }

      findNewState(prev)

      const applyNewState = (items: FileItem[]): FileItem[] =>
        items.map(item => {
          const shouldApplyNewState =
            item.path === path || item.path.startsWith(path + '/')
          const isSelected = shouldApplyNewState ? newState : false

          if (item.type === 'file' && isSelected) {
            newSelectedPaths.push(baseUri + item.path)
            if (!commonDescription && item.seriesDescription) {
              commonDescription = item.seriesDescription
            }
          }

          return {
            ...item,
            selected: isSelected,
            children: item.children ? applyNewState(item.children) : undefined,
          }
        })

      const updatedHierarchy = applyNewState(prev)
      setSelectedPaths(newSelectedPaths)
      setCommonSeriesDescription(
        newSelectedPaths.length > 0 ? commonDescription : '',
      )
      return updatedHierarchy
    })
  }

  const renderCheckbox = (item: FileItem) => (
    <Checkbox
      style={{ marginRight: '8px' }}
      checked={item.selected || false}
      onChange={() => toggleDirectorySelection(item.path)}
    />
  )

  React.useEffect(() => {
    if (exportButtonSuperAnnotateRef.current && isUploadedDicomAsMP4) {
      editVideoCount({
        videoId: video._id,
        export_count: video.export_count + 1,
        batch: video.batch,
      })
      addVideoImportLog(superAnnotateLogPayload)
      setSuperAnnotateLogPayload([])
      exportButtonSuperAnnotateRef.current = false
    }
  }, [isUploadedDicomAsMP4])

  useEffect(() => {
    const fetchZipFile = async () => {
      try {
        setIsLoading(true)
        const response = await fetch(url)
        const blob = await response.blob()
        const arrayBuffer = await blob.arrayBuffer()
        const zip = await JSZip.loadAsync(arrayBuffer)

        const buildHierarchy = async (zip: JSZip): Promise<FileItem[]> => {
          const items: FileItem[] = []
          const folderMap: Record<string, FileItem> = {}

          const promises: Promise<void>[] = []

          zip.forEach((relativePath, file) => {
            // Only display dicom files
            if (file.name.startsWith('.') || !file.name.endsWith('.dcm')) {
              return
            }

            const parts = relativePath.split('/')
            let seriesDescription = ''

            if (!file.dir && file.name.endsWith('.dcm')) {
              const promise = file.async('arraybuffer').then(fileContent => {
                const dataSet = dicomParser.parseDicom(
                  new Uint8Array(fileContent),
                )
                seriesDescription = dataSet.string('x0008103e') || ''

                parts.reduce((acc, part, index) => {
                  const currentPath = parts.slice(0, index + 1).join('/')
                  const isFile = index === parts.length - 1 && !file.dir
                  if (!acc[currentPath]) {
                    const newItem: FileItem = {
                      type: isFile ? 'file' : 'directory',
                      name: part,
                      path: currentPath,
                      seriesDescription: isFile
                        ? seriesDescription
                        : acc[currentPath]?.seriesDescription,
                      children: isFile ? undefined : [],
                    }
                    if (index === 0) {
                      items.push(newItem)
                    } else {
                      acc[parts.slice(0, index).join('/')].children?.push(
                        newItem,
                      )
                    }
                    acc[currentPath] = newItem
                  } else if (!isFile && !acc[currentPath].seriesDescription) {
                    acc[currentPath].seriesDescription = seriesDescription
                  }
                  return acc
                }, folderMap)
              })
              promises.push(promise)
            } else {
              parts.reduce((acc, part, index) => {
                const currentPath = parts.slice(0, index + 1).join('/')
                const isFile = index === parts.length - 1 && !file.dir
                if (!acc[currentPath]) {
                  const newItem: FileItem = {
                    type: isFile ? 'file' : 'directory',
                    name: part,
                    path: currentPath,
                    children: isFile ? undefined : [],
                  }
                  if (index === 0) {
                    items.push(newItem)
                  } else {
                    acc[parts.slice(0, index).join('/')].children?.push(newItem)
                  }
                  acc[currentPath] = newItem
                }
                return acc
              }, folderMap)
            }
          })

          await Promise.all(promises)
          return items
        }

        const hierarchy = await buildHierarchy(zip)
        setHierarchy(hierarchy)
        setIsLoading(false)
      } catch (error) {
        console.error('Error reading zip file:', error)
        setIsLoading(false)
      }
    }

    fetchZipFile()
  }, [url])

  const renderFiles = (files: FileItem[]) => {
    const sortedFiles = files.sort((a, b) =>
      a.name.localeCompare(
        b.name,
        navigator.languages[0] || navigator.language,
        {
          numeric: true,
          ignorePunctuation: true,
          sensitivity: 'base',
        },
      ),
    )

    return sortedFiles.map((file, index) => (
      <div key={file.path} className="file-item">
        {index + 1}. {file.name}
      </div>
    ))
  }

  const renderDirectories = (directories: FileItem[]) => {
    const sortedDirectories = directories.sort((a, b) =>
      a.name.localeCompare(
        b.name,
        navigator.languages[0] || navigator.language,
        {
          numeric: true,
          ignorePunctuation: true,
          sensitivity: 'base',
        },
      ),
    )

    return sortedDirectories.map(directory => (
      <div key={directory.path} className="directory">
        <div className="directory-header">
          {!video.uri.includes('-batch') && renderCheckbox(directory)}
          <strong>{directory.name}</strong>
          {directory.seriesDescription && (
            <span> ({directory.seriesDescription})</span>
          )}
        </div>
        <div className="directory-content">
          {directory.children &&
            renderFiles(
              directory.children.filter(item => item.type === 'file'),
            )}
          {directory.children &&
            renderDirectories(
              directory.children.filter(item => item.type === 'directory'),
            )}
        </div>
      </div>
    ))
  }

  const [clickCopyURI, setClickCopyURI] = useState(false)

  const renderCopyURIButton = () => {
    const toggleAfterCopy = () => {
      setClickCopyURI(true)
      if (video.uri) {
        navigator.clipboard.writeText(video.uri)
        Toaster.show({
          icon: 'tick',
          intent: Intent.SUCCESS,
          message: 'Copied to Clipboard!',
        })
      }
    }
    return !clickCopyURI ? (
      <CopyTwoTone
        style={{ marginLeft: '5px' }}
        onClick={() => toggleAfterCopy()}></CopyTwoTone>
    ) : (
      <CopyFilled style={{ marginLeft: '5px' }}> </CopyFilled>
    )
  }

  const [clickCopyPSLink, setClickCopyPSLink] = useState(false)

  const renderCopyPSLinkButton = () => {
    const toggleAfterCopy = () => {
      setClickCopyPSLink(true)
      if (url) {
        navigator.clipboard.writeText(url)
        Toaster.show({
          icon: 'tick',
          intent: Intent.SUCCESS,
          message: 'Copied to Clipboard!',
        })
        setTimeout(() => {
          const viewerUrl = 'https://www.imaios.com/en/IMAIOS-DICOM-Viewer'
          window.open(viewerUrl, '_blank')
        }, 1200)
      }
    }

    return !clickCopyPSLink ? (
      <CopyTwoTone
        style={{ marginLeft: '5px' }}
        onClick={() => toggleAfterCopy()}></CopyTwoTone>
    ) : (
      <CopyFilled style={{ marginLeft: '5px' }}> </CopyFilled>
    )
  }

  const [isDownloaded, setIsDownloaded] = useState(false)

  const handleDownload = () => {
    if (!isDownloaded) {
      editVideoCount({
        videoId: video._id,
        download_count: video.download_count + 1,
        batch: video.batch,
      })
      setIsDownloaded(true)
    }
  }

  const renderDownloadButton = () => {
    return (
      <div
        style={{
          marginTop: '10px',
        }}>
        <Stack justifyContent="flex-start">
          <a
            href={url}
            download
            style={{ textDecoration: 'none' }}
            onClick={e => {
              if (isDownloaded) {
                e.preventDefault()
              } else {
                handleDownload()
              }
            }}>
            <Button
              disabled={isDownloaded}
              type="primary"
              style={{ marginBottom: '0.5rem' }}>
              Download Zip File
            </Button>
          </a>
        </Stack>
      </div>
    )
  }

  const handleUploadAndExport = (
    selectedPaths: string[],
    destinationProjectSuperAnnotate: string,
  ) => {
    const videoRegion =
      video.region != null && video.region.trim() !== ''
        ? video.region
        : 'No region specified'
    const doctorID = video.doctor
    const videoDoctorEmail = get(allDoctors[doctorID], 'email', '')
    const userEmail = user?.name ?? 'No email specified'
    const dateTime = new Date().toLocaleString('en-US', {
      timeZone: 'America/Los_Angeles',
    })
    const timeOfImport = dateTime.toString()

    const dateNow = new Date().toISOString()
    const slicedDate = dateNow.slice(0, -1)
    const uploadDate = slicedDate.concat('000Z')

    function extractVideoName(s3Urls: string | any[]) {
      if (!s3Urls || s3Urls.length === 0) {
        throw new Error('The input array is empty')
      }
      const s3Url = s3Urls[0]
      const parts = s3Url.split('/')
      const videoNameWithoutExt = parts[parts.length - 2]
      const videoName = `${videoNameWithoutExt}.mp4`

      return videoName
    }

    const videoName = extractVideoName(selectedPaths)

    const logPayload = {
      videoName,
      videoDoctorEmail,
      userEmail,
      timeOfImport,
      uploadDate,
      videoRegion,
    }
    setSuperAnnotateLogPayload(logPayload)

    const videoTags = [
      ...(video.tags || []),
      ...(video.classificationTags || []),
    ]

    const videoDoctor = get(allDoctors[doctorID], 'fullname', '')

    uploadDicomAsMP4(
      selectedPaths,
      destinationProjectSuperAnnotate,
      commonSeriesDescription,
      videoTags,
      videoDoctor,
      videoRegion,
    )
    exportButtonSuperAnnotateRef.current = true
  }

  const [destinationProjectSuperAnnotate, setDestinationProjectSuperAnnotate] =
    useState('Select Project')

  const renderDestProjectsSuperAnnotate = () => {
    const selectedDestProject = String(destinationProjectSuperAnnotate)

    const { Option } = Select

    const handleChange = (value: string) => {
      setDestinationProjectSuperAnnotate(value)
    }

    return (
      <Stack
        style={{
          paddingRight: 8,
          paddingTop: 0,
          paddingBottom: 10,
        }}>
        <Stack style={{ paddingTop: 5, paddingRight: 8 }}>
          Destination Projects in Super Annotate
        </Stack>
        <Select
          defaultValue={selectedDestProject}
          style={{ width: 250 }}
          onChange={handleChange}>
          <Option value="Knee MRI projects">Knee MRI projects</Option>
          <Option value="Shoulder MRI projects">Shoulder MRI projects</Option>
          <Option value="Hip MRI projects">Hip MRI projects</Option>
        </Select>
      </Stack>
    )
  }

  return (
    <Stack vertical gutter={Spacing.MEDIUM}>
      <Stack vertical>
        <Text
          style={{
            marginTop: '15px',
            fontSize: '18px',
          }}
          fontSize={16}
          fontWeight="bold">
          {video.filename}
        </Text>
        <Text
          style={{
            marginBottom: '4px',
            fontSize: '12.5px',
          }}
          color={Colors.PALE_GREY}
          fontSize={12}>
          {video.uri}
          {renderCopyURIButton()}
        </Text>
        <Text
          style={{
            marginBottom: '4px',
            fontSize: '12.5px',
          }}
          color={Colors.PALE_GREY}
          fontSize={12}>
          {`Copy presigned URL and open IMAIOS viewer in a separate tab. Paste the copied URL in the 'Import from url' button.`}{' '}
          {renderCopyPSLinkButton()}
          {renderDownloadButton()}
        </Text>

        {/* render KLIndivCSVViewer for individual studies, not main .zip files */}
        {csvURL && !video.uri.includes('-batch') && (
          <div
            style={{
              marginBottom: '10px',
            }}>
            <KLIndivCSVViewer
              url={csvURL}
              studyName={video.filename.split('.').slice(0, -1).join('.')}
            />
          </div>
        )}

        <div className="zip-viewer">
          {isLoading ? (
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Spin />
              <span style={{ marginLeft: '16px' }}>
                Loading Dicom files hierarchy...
              </span>
            </div>
          ) : (
            <div>
              {!video.uri.includes('-batch') &&
                renderDestProjectsSuperAnnotate()}
              {!video.uri.includes('-batch') && (
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    marginBottom: '10px',
                  }}>
                  <Button
                    type="primary"
                    onClick={() =>
                      handleUploadAndExport(
                        selectedPaths,
                        destinationProjectSuperAnnotate,
                      )
                    }
                    disabled={
                      isUploadingDicomAsMP4 ||
                      !(
                        selectedPaths.length > 0 &&
                        destinationProjectSuperAnnotate !== 'Select Project'
                      )
                    }>
                    Upload Dicoms to Super Annotate
                  </Button>
                  {isUploadingDicomAsMP4 && (
                    <div style={{ marginLeft: 16 }}>
                      <Spin tip="Uploading Dicoms as MP4..." />
                    </div>
                  )}
                </div>
              )}
              {hierarchy.map((mainDirectory, index) => (
                <div key={mainDirectory.path} className="primary-directory">
                  <div className="primary-directory-header">
                    <strong>
                      Study {index + 1}. {mainDirectory.name}
                    </strong>
                  </div>
                  <div className="primary-directory-container">
                    {renderDirectories(
                      mainDirectory.children?.filter(
                        item => item.type === 'directory',
                      ) || [],
                    )}
                    {renderFiles(
                      mainDirectory.children?.filter(
                        item => item.type === 'file',
                      ) || [],
                    )}
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
      </Stack>
    </Stack>
  )
}

const getUploadDicomAsMP4LoadingSelector = createLoadingSelector([
  uploadDicomAsMP4.request,
])

const getUploadDicomAsMP4SuccessSelector = createSuccessSelector([
  uploadDicomAsMP4.success,
])

const mapStateToProps = (state: RootState) => ({
  isUploadingDicomAsMP4: getUploadDicomAsMP4LoadingSelector(state),
  isUploadedDicomAsMP4: getUploadDicomAsMP4SuccessSelector(state),
  allDoctors: getAllDoctors(state),
})

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      uploadDicomAsMP4: uploadDicomAsMP4Flow,
      editVideoCount: editVideoCountFlow,
      addVideoImportLog: addVideoImportLogFlow,
    },
    dispatch,
  )

export default connect(mapStateToProps, mapDispatchToProps)(KLZipFileViewer)
