import './NestedVideoTable.css'

import { WarningTwoTone } from '@ant-design/icons'
import { useAuth0 } from '@auth0/auth0-react'
import { Button, List, Modal, Select, Table } from 'antd'
import { PaginationConfig } from 'antd/lib/pagination'
import {
  FilterValue,
  SorterResult,
  TablePaginationConfig,
} from 'antd/lib/table/interface'
import { Doctor, FilterFields, VideoBatch, VideoSegment } from 'common/models'
import { forEach, get, isEmpty, values } from 'lodash'
import React, { ReactText, useEffect, useRef, useState } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators, Dispatch } from 'redux'
import {
  addDoctorIDsFlow,
  addFiltersCheckFlow,
  addUserFiltersFlow,
} from 'store/restoreVideoState/actions'
import { addInputSearchFlow } from 'store/restoreVideoState/actions'
import { initialFiltersCheckSelector } from 'store/restoreVideoState/selectors'
import { RootState } from 'store/rootReducer'
import {
  addVideoImportLogFlow,
  batchUploadToSuperAnnotateFlow,
  editVideoCountFlow,
} from 'store/videoSegments/actions'

import { Spacing } from '../../../common/stylings'
import Paper from '../../../components/Paper'
import Stack from '../../../components/Stack'
import { TableWrapper } from '../../../components/TableWrapper'
import { SearchProps } from '../../../main/videoAssets/FilterPanel/index'
import { getInnerColumns, getOuterColumns } from './VideoColumns'

type Props = FilterProps &
  SearchProps &
  OwnProps &
  StoreProps<typeof mapStateToProps, typeof mapDispatchToProps>

export interface FilterProps {
  onFiltersChange: (
    filters:
      | Record<string, ReactText[] | null>
      | Record<string, FilterValue | null>
      | null,
  ) => void
  filters: Record<string, React.ReactText[] | null> | null
  onDoctorIdsChange: (ids: string[]) => void
}
interface OwnProps {
  filteredVideos: Dict<VideoSegment>
  filterFields: FilterFields
  videoBatches: Dict<VideoBatch>
  totalBatches: number
  allDoctors: Dict<Doctor>
  numVidoesSent: number
  totalVideos: number
  currentPage: number
  setPageNumber: (page: number) => void
  isInclusiveSearch: boolean
  toggleSearchPattern: (isInclusive: boolean) => void
  isBatchUploadingToSuperAnnotate: any
  isBatchUploadedToSuperAnnotate: any
  getBatchUploadToSuperAnnotateErrorSelector: any
  exportLogs: any
  trainingLogs: any
  isSMEVideoAssetsPage: boolean
  isDicomAssetsPage: boolean
}

const NestedVideoTable: React.FC<Props> = ({
  filteredVideos,
  filterFields,
  videoBatches,
  totalBatches,
  allDoctors,
  numVidoesSent,
  totalVideos,
  currentPage,
  setPageNumber,
  filters,
  onFiltersChange,
  onDoctorIdsChange,
  isInclusiveSearch,
  toggleSearchPattern,
  onSearch,
  addDoctorID,
  addInputSearch,
  addUserFilters,
  initialFiltersCheck,
  addFiltersCheck,
  batchUploadToSuperAnnotate,
  isBatchUploadedToSuperAnnotate,
  isBatchUploadingToSuperAnnotate,
  getBatchUploadToSuperAnnotateErrorSelector,
  editVideoCount,
  addVideoImportLog,
  exportLogs,
  trainingLogs,
  isSMEVideoAssetsPage,
  isDicomAssetsPage,
}) => {
  const [hasData, checkDataExist] = useState(false)
  const [tagFilterSelectedKeys, setTagFilter] = useState<string[]>([])
  const { user } = useAuth0()

  const clearFilters = () => {
    onDoctorIdsChange([])
    onFiltersChange(null)
    onSearch('')

    addDoctorID([])
    addUserFilters(null)
    addInputSearch('')
    addFiltersCheck(false)
    setSelectedRowKeys([])
    setSelectedRow({
      videoIDs: [],
      videoNames: [],
      videoURIS: [],
      videoTags: [],
      videoBatches: [],
      videoExportCounts: [],
      videoDoctors: [],
      videoClassificationTags: [],
    })
  }

  const handleChange = (
    pagination: PaginationConfig | TablePaginationConfig,
    filters:
      | Record<string, ReactText[] | null>
      | Record<string, FilterValue | null>,
    sorter: SorterResult<VideoBatch> | SorterResult<VideoBatch>[],
  ) => {
    const selectedDoctorNames = get(filters, 'doctor', []) as string[]
    if (isEmpty(selectedDoctorNames)) {
      onDoctorIdsChange([])
      onFiltersChange(filters)
      return
    }

    // As doctors may have multiple emails under the same name,
    // for some reasons Antd table concatenates the uids into one string separated by comma
    // You may refer to VideoColumns.tsx -> getDoctorIdsByName for more details on the filters
    const selectedDoctorIds: string[] = []
    forEach(allDoctors, (doctor, doctorId) => {
      if (selectedDoctorNames.includes(get(doctor, 'fullname', ''))) {
        selectedDoctorIds.push(doctorId)
      }
    })
    onDoctorIdsChange(selectedDoctorIds)
    onFiltersChange(filters)
  }

  const handleIndivChange = (
    pagination: PaginationConfig | TablePaginationConfig, //TODO TablePaginationConfig might be imported from "antd/lib/table/interface" or "antd"
    filters:
      | Record<string, ReactText[] | null>
      | Record<string, FilterValue | null>,
    sorter: SorterResult<VideoSegment> | SorterResult<VideoSegment>[],
  ) => {
    const selectedDoctorNames = get(filters, 'doctor', []) as string[]
    if (isEmpty(selectedDoctorNames)) {
      onDoctorIdsChange([])
      onFiltersChange(filters)
      return
    }

    const selectedDoctorIds: string[] = []
    forEach(allDoctors, (doctor, doctorId) => {
      if (selectedDoctorNames.includes(get(doctor, 'fullname', ''))) {
        selectedDoctorIds.push(doctorId)
      }
    })
    onDoctorIdsChange(selectedDoctorIds)
    onFiltersChange(filters)
  }

  const checkVideoDataExist = () => {
    if (isEmpty(videoBatches)) {
      return checkDataExist(false)
    }
    return checkDataExist(true)
  }

  useEffect(checkVideoDataExist, [videoBatches])

  const [selectedRowKeys, setSelectedRowKeys] = useState([])
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [destinationProjectSuperAnnotate, setDestinationProjectSuperAnnotate] =
    useState('Select Project')
  const [selectedRow, setSelectedRow] = useState<any>({
    videoIDs: [],
    videoNames: [],
    videoURIS: [],
    videoTags: [],
    videoBatches: [],
    videoExportCounts: [],
    videoDoctors: [],
    videoRegions: [],
    videoClassificationTags: [],
  })
  const exportButtonRef = useRef(false)

  const onSelectChange = (selectedRowKeys: any) => {
    setSelectedRowKeys(selectedRowKeys)

    const videoIDs: string[] = []
    const videoNames: string[] = []
    const videoURIS: string[] = []
    const videoTags: string[][] = []
    const videoBatches: string[] = []
    const videoExportCounts: string[] = []
    const videoDoctors: string[] = []
    const videoRegions: string[] = []
    const videoClassificationTags: string[][] = []

    for (const index in selectedRowKeys) {
      const portions = selectedRowKeys[index].split('--')
      const videoID = portions[0]
      const videoName = portions[1]
      const videoURI = portions[2]
      const videoTag = portions[3]
      const videoTagsArray = videoTag.split(',')
      const videoBatch = portions[4]
      const videoExportCount = portions[5]
      const videoDoctor = portions[6]
      const videoRegion = portions[7]
      const videoClassificationTag = portions[8]
      const videoClassificationTagsArray = videoClassificationTag.split(',')

      videoIDs.push(videoID)
      videoNames.push(videoName)
      videoURIS.push(videoURI)
      videoTags.push(videoTagsArray)
      videoBatches.push(videoBatch)
      videoExportCounts.push(videoExportCount)
      videoDoctors.push(videoDoctor)
      videoRegions.push(videoRegion)
      videoClassificationTags.push(videoClassificationTagsArray)

      const amendedRows = {
        videoIDs,
        videoNames,
        videoURIS,
        videoTags,
        videoBatches,
        videoExportCounts,
        videoDoctors,
        videoRegions,
        videoClassificationTags,
      }

      setSelectedRow(amendedRows)
    }

    if (selectedRowKeys.length === 0) {
      const videoIDs: string[] = []
      const videoNames: string[] = []
      const videoURIS: string[] = []
      const videoTags: string[][] = []
      const videoBatches: string[] = []
      const videoExportCounts: string[] = []
      const videoDoctors: string[] = []
      const videoRegions: string[] = []
      const videoClassificationTags: string[][] = []

      const emptyArray = {
        videoIDs,
        videoNames,
        videoURIS,
        videoTags,
        videoBatches,
        videoExportCounts,
        videoDoctors,
        videoRegions,
        videoClassificationTags,
      }

      setSelectedRow(emptyArray)
    }
  }

  const rowSelection = {
    selectedRowKeys,
    preserveSelectedRowKeys: true,
    onChange: onSelectChange,
    getCheckboxProps: (record: any) => ({
      disabled:
        selectedRow.videoNames.length >= 10 &&
        !selectedRow.videoNames.includes(record.filename),
    }),
  }

  // Inner table
  const expandedRow = (row: { batch: string }) => {
    const columns = getInnerColumns(
      filterFields,
      allDoctors,
      isInclusiveSearch,
      toggleSearchPattern,
      tagFilterSelectedKeys,
      setTagFilter,
      filters,
      exportLogs,
      trainingLogs,
      isSMEVideoAssetsPage,
    )

    let nestedData: VideoSegment[] = []
    const currentBatch = values(videoBatches)
    for (let i = 0; i < currentBatch.length; i++) {
      if (row.batch === currentBatch[i].batch) {
        nestedData = currentBatch[i].videos
        break
      }
    }

    nestedData.sort((a, b) =>
      a.filename.localeCompare(
        b.filename,
        navigator.languages[0] || navigator.language,
        {
          numeric: true,
          ignorePunctuation: true,
          sensitivity: 'base',
        },
      ),
    )

    return (
      <Table
        rowSelection={rowSelection}
        columns={columns}
        dataSource={nestedData}
        pagination={false}
        rowKey={(video: VideoSegment) =>
          `${video._id}${'--'}${video.filename}${'--'}${video.uri}${'--'}${
            video.tags
          }${'--'}${video.batch}${'--'}${video.export_count}${'--'}${
            video.doctor
          }${'--'}${video.region}${'--'}${video.classificationTags}`
        }
        onChange={handleIndivChange}
      />
    )
  }

  // Outer Table
  const columns = getOuterColumns(
    filterFields,
    allDoctors,
    videoBatches,
    isInclusiveSearch,
    toggleSearchPattern,
    tagFilterSelectedKeys,
    setTagFilter,
    filters,
    isSMEVideoAssetsPage,
  )

  const showModal = () => {
    setIsModalOpen(true)
  }

  const [superAnnotateLogPayload, setSuperAnnotateLogPayload] = useState<any>(
    [],
  )

  const handleOk = () => {
    const videoURIs = selectedRow.videoURIS
    let videoTags = selectedRow.videoTags
    const videoNames = selectedRow.videoNames
    const videoDoctors = selectedRow.videoDoctors
    const videoRegions = selectedRow.videoRegions
    const videoClassificationTags = selectedRow.videoClassificationTags

    const doctors = videoDoctors.map((id: string | number) =>
      get(allDoctors[id], ['fullname'], ''),
    )

    const doctorsEmail = videoDoctors.map((id: string | number) =>
      get(allDoctors[id], ['email'], ''),
    )

    for (const videoTag in videoTags) {
      videoTags[videoTag] =
        videoTags[videoTag] === 'undefined' ? null : videoTags[videoTag]
    }

    for (const classificationTag in videoClassificationTags) {
      videoClassificationTags[classificationTag] =
        videoClassificationTags[classificationTag] === 'undefined'
          ? null
          : videoClassificationTags[classificationTag]
    }

    videoNames.forEach((videoName: any, index: any) => {
      const videoDoctorEmail = doctorsEmail[index]
      const videoRegion =
        videoRegions[index] != null &&
        videoRegions[index].trim() !== '' &&
        videoRegions[index] != 'undefined'
          ? videoRegions[index]
          : 'No region specified'

      const userEmail = user?.name ?? 'No email specified'
      const dateTime = new Date().toLocaleString('en-US', {
        timeZone: 'America/Los_Angeles',
      })
      const timeOfImport = dateTime.toString()

      const dateNow = new Date().toISOString()
      const slicedDate = dateNow.slice(0, -1)
      const uploadDate = slicedDate.concat('000Z')

      const logPayload = {
        videoName,
        videoDoctorEmail,
        userEmail,
        timeOfImport,
        uploadDate,
        videoRegion,
      }

      setSuperAnnotateLogPayload((prevSuperAnnotateLogs: any) => [
        ...prevSuperAnnotateLogs,
        logPayload,
      ])
    })

    videoTags = videoTags.map((tags: any, index: string | number) => [
      ...tags,
      ...(videoClassificationTags[index] || []),
    ])

    const payloadSuperAnnotate = {
      videoNames,
      videoURIs,
      videoTags,
      destinationProjectSuperAnnotate,
      doctors,
      videoRegions,
    }

    batchUploadToSuperAnnotate(payloadSuperAnnotate)

    exportButtonRef.current = true

    setSelectedRowKeys([])
  }

  const handleCancel = () => {
    setIsModalOpen(false)
  }

  const hasSelected = selectedRowKeys.length > 0

  const { Option } = Select

  const handleProjectChange = (value: string) => {
    setDestinationProjectSuperAnnotate(value)
  }

  const [failedExportIndex, setFailedExportIndex] = useState(0)

  // When there is an error in batch video exports, obtain the index of video that failed
  React.useEffect(() => {
    if (
      getBatchUploadToSuperAnnotateErrorSelector.message !== '' &&
      getBatchUploadToSuperAnnotateErrorSelector.message &&
      exportButtonRef
    ) {
      const error =
        getBatchUploadToSuperAnnotateErrorSelector.message.split(' ')[3]
      const index = error.charAt(0)
      const indexOfFailedVideoExport = parseInt(index) - 1
      setFailedExportIndex(indexOfFailedVideoExport)
      exportButtonRef.current = false
    }
  }, [getBatchUploadToSuperAnnotateErrorSelector])

  // If index of video is not 0, this means that there is a video that failed to be exported to Super Annotate.
  // Update video export count of previous videos in the list that mananged to be exported to Super Annotate.
  // Send import log only for successfully exported videos.
  // Subsequent videos are not exported to Super Annotate, thus no export count is updated.
  React.useEffect(() => {
    if (failedExportIndex !== 0) {
      for (let i = 0; i < failedExportIndex; i++) {
        const videoID = selectedRow.videoIDs[i]
        const videoExportCount = selectedRow.videoExportCounts[i]
        const videoBatch = selectedRow.videoBatches[i]

        addVideoImportLog(superAnnotateLogPayload[i])

        editVideoCount({
          videoId: videoID,
          export_count: parseInt(videoExportCount) + 1,
          batch: videoBatch,
        })
      }
      setSuperAnnotateLogPayload([])
    }
  }, [failedExportIndex])

  // Update video export count for successful batch video uploads
  React.useEffect(() => {
    if (exportButtonRef && isBatchUploadedToSuperAnnotate === true) {
      for (let i = 0; i < superAnnotateLogPayload.length; i++) {
        addVideoImportLog(superAnnotateLogPayload[i])
      }

      setSuperAnnotateLogPayload([])

      for (let i = 0; i < selectedRow.videoIDs.length; i++) {
        const videoID = selectedRow.videoIDs[i]
        const videoExportCount = selectedRow.videoExportCounts[i]
        const videoBatch = selectedRow.videoBatches[i]

        editVideoCount({
          videoId: videoID,
          export_count: parseInt(videoExportCount) + 1,
          batch: videoBatch,
        })
      }
      exportButtonRef.current = false
    }
  }, [isBatchUploadedToSuperAnnotate])

  React.useEffect(() => {
    if (isBatchUploadedToSuperAnnotate) {
      setIsModalOpen(false)
    }
  }, [isBatchUploadedToSuperAnnotate])

  return (
    <>
      <Paper>
        <Stack vertical gutter={Spacing.SMALL}>
          <Stack>
            <Button
              style={{ marginBottom: 16, marginRight: 10 }}
              onClick={clearFilters}>
              Clear filters
            </Button>
            {initialFiltersCheck && (
              <div style={{ marginTop: 3, color: 'red', marginRight: 10 }}>
                <WarningTwoTone
                  twoToneColor="#ff0000"
                  style={{ marginRight: 5, marginLeft: 5, fontSize: 25 }}
                />
                Clear filters first to change filter settings
              </div>
            )}
            {isDicomAssetsPage ? null : (
              <div
                style={{
                  marginBottom: 16,
                }}>
                <Button
                  type="primary"
                  onClick={showModal}
                  disabled={!hasSelected}>
                  Export videos to Super Annotate
                </Button>
                <span
                  style={{
                    marginLeft: 8,
                  }}>
                  {hasSelected
                    ? `Selected ${selectedRowKeys.length} items.`
                    : ''}
                </span>
                <span
                  style={{
                    marginLeft: 8,
                    color: 'red',
                  }}>
                  {selectedRowKeys.length >= 10
                    ? `Maximum limit of 10 videos selected.`
                    : ''}
                </span>
              </div>
            )}

            <Modal
              title="List of videos to be exported into Super Annotate"
              visible={isModalOpen}
              onOk={handleOk}
              onCancel={handleCancel}
              okText="Export to Super Annotate"
              okButtonProps={{
                loading: isBatchUploadingToSuperAnnotate,
                disabled:
                  destinationProjectSuperAnnotate !== 'Select Project'
                    ? false
                    : true,
              }}>
              <List
                header={<div>Video Names</div>}
                size="default"
                bordered
                dataSource={selectedRow.videoNames}
                renderItem={(item, index) => (
                  <List.Item>
                    {index + 1}. {item}
                  </List.Item>
                )}
              />
              <Stack vertical style={{ paddingTop: 10 }}>
                <Stack
                  style={{ paddingRight: 8, paddingTop: 5, paddingBottom: 5 }}>
                  Destination Projects in Super Annotate:
                </Stack>
                <Select
                  defaultValue={destinationProjectSuperAnnotate}
                  style={{ width: 280 }}
                  onChange={handleProjectChange}>
                  <Option value="Sandbox-Videos">Sandbox-Videos</Option>
                  <Option value="Knee-Videos">Knee-Videos</Option>
                  <Option value="Shoulder-Videos">Shoulder-Videos</Option>
                  <Option value="Hip-Videos">Hip-Videos</Option>
                  <Option value="Activity Recognition">
                    Activity Recognition
                  </Option>
                  <Option value="Medusa-Integration-1FPS-A">
                    Medusa-Integration-1FPS-A
                  </Option>
                  <Option value="Medusa-Integration-1FPS-B">
                    Medusa-Integration-1FPS-B
                  </Option>
                  <Option value="Medusa-Integration-1FPS-C">
                    Medusa-Integration-1FPS-C
                  </Option>
                </Select>
              </Stack>
            </Modal>
          </Stack>
          <TableWrapper>
            <Table
              columns={columns}
              expandedRowRender={expandedRow}
              dataSource={hasData ? values(videoBatches) : undefined}
              rowKey={(batch: VideoBatch) => batch.batch}
              onChange={handleChange}
              pagination={{
                total: totalBatches,
                current: currentPage,
                defaultCurrent: 1,
                defaultPageSize: 5,
                showSizeChanger: false,
                onChange: page => setPageNumber(page),
              }}
            />
          </TableWrapper>
        </Stack>
        <div
          style={{
            fontWeight: 200,
          }}>
          Total number of {isDicomAssetsPage ? 'assets' : 'videos'} on current
          page: {numVidoesSent}
          <div>Total number of batches: {totalBatches}</div>
        </div>
      </Paper>
    </>
  )
}

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      addDoctorID: addDoctorIDsFlow,
      addInputSearch: addInputSearchFlow,
      addUserFilters: addUserFiltersFlow,
      addFiltersCheck: addFiltersCheckFlow,
      batchUploadToSuperAnnotate: batchUploadToSuperAnnotateFlow,
      editVideoCount: editVideoCountFlow,
      addVideoImportLog: addVideoImportLogFlow,
    },
    dispatch,
  )

const mapStateToProps = (s: RootState) => ({
  initialFiltersCheck: initialFiltersCheckSelector(s),
})

export default connect(null, mapDispatchToProps)(NestedVideoTable)
