import { FilterFields, VideoSegment } from 'common/models'
import { get, keyBy, reduce, uniq } from 'lodash'
import { ActionType, getType } from 'typesafe-actions'

import * as videoSegmentsActions from './actionCreators'
import { VideoPropertyPayload } from './actionCreators'

export type VideoSegmentsActionType = ActionType<typeof videoSegmentsActions>

interface VideoSegmentState {
  filteredVideoList: Dict<VideoSegment>
  total: number
  videoSummary: Dict<VideoSegment>
  startDate: string
  endDate: string
  filterFields: FilterFields
  number_videos_sent: number
  total_number_of_batches: number
}

const initialState: Readonly<VideoSegmentState> = {
  filteredVideoList: {},
  total: 0,
  videoSummary: {},
  startDate: '',
  endDate: '',
  filterFields: {
    regions: [],
    doctors: [],
    procedures: [],
    tags: [],
    classificationTags: [],
  },
  number_videos_sent: 0,
  total_number_of_batches: 0,
}

export default (state = initialState, action: VideoSegmentsActionType) => {
  switch (action.type) {
    case getType(videoSegmentsActions.fetchVideoSegmentsWithFilter.success): {
      const videos: VideoSegment[] = get(action.payload, ['data', 'videos'], [])
      // Parses and adds batch name to video object
      videos.forEach(video => {
        const batchName = video.uri.split('/', 10)[4]
        video.batch = batchName
      })
      const videoDict: Dict<VideoSegment> = keyBy(videos, '_id')
      const total: number = get(action.payload, ['data', 'total'], 0)
      return {
        ...state,
        filteredVideoList: videoDict,
        total: total,
      }
    }

    case getType(videoSegmentsActions.fetchVideoBatchesWithFilter.success): {
      const batches = get(action.payload, ['data', 'batches'], [])
      const batchDict: Dict<VideoSegment> = keyBy(batches, 'name')
      const total: number = get(action.payload, ['data', 'total'], 0)
      const number_videos_sent: number = get(
        action.payload,
        ['data', 'number_videos_sent'],
        0,
      )
      const total_number_of_batches: number = get(
        action.payload,
        ['data', 'total_number_of_batches'],
        0,
      )

      return {
        ...state,
        total,
        batchDict,
        number_videos_sent,
        total_number_of_batches,
      }
    }

    case getType(videoSegmentsActions.fetchPublicVideoURL.success): {
      const videoId: string = get(action.payload, ['videoId'], '')
      const publicURL: string = get(action.payload, ['publicURL'], '')

      const batch: string = get(action.payload, ['batch'], '')
      const newVideos = state['batchDict'][batch]['videos']
      const newVideosArray = []
      for (const video of newVideos) {
        if (videoId === video['_id']) {
          const newVideoObj = {
            ...video,
            publicURL,
          }
          newVideosArray.push(newVideoObj)
        } else {
          const newVideoObj = { ...video }
          newVideosArray.push(newVideoObj)
        }
      }

      return {
        ...state,
        batchDict: {
          ...state['batchDict'],
          [batch]: {
            ...state['batchDict'][batch],
            videos: newVideosArray,
          },
        },
        filteredVideoList: {
          ...state.filteredVideoList,
          [videoId]: {
            ...state.filteredVideoList[videoId],
            publicURL,
          },
        },
      }
    }

    case getType(videoSegmentsActions.changeVideoAssetDateRange): {
      const { startDate, endDate } = action.payload
      return {
        ...state,
        startDate,
        endDate,
      }
    }

    case getType(videoSegmentsActions.extractFrames.success): {
      const returnMessageFromDarwin: string = action.payload
      return {
        ...state,
        returnMessageFromDarwin,
      }
    }

    case getType(videoSegmentsActions.uploadDicomAsMP4.success): {
      const returnMessageFromS3: string = action.payload
      return {
        ...state,
        returnMessageFromS3,
      }
    }

    case getType(videoSegmentsActions.uploadToDarwin.success): {
      const returnMessageFromDarwin: string = action.payload
      return {
        ...state,
        returnMessageFromDarwin,
      }
    }

    case getType(videoSegmentsActions.uploadToSuperAnnotate.success): {
      const returnMessageFromSuperAnnotate: string = action.payload
      return {
        ...state,
        returnMessageFromSuperAnnotate,
      }
    }

    case getType(videoSegmentsActions.batchUploadToSuperAnnotate.success): {
      const returnMessageFromSuperAnnotate: string = action.payload
      return {
        ...state,
        returnMessageFromSuperAnnotate,
      }
    }

    case getType(videoSegmentsActions.batchUploadToDarwin.success): {
      const returnMessageFromDarwin: string = action.payload
      return {
        ...state,
        returnMessageFromDarwin,
      }
    }

    case getType(videoSegmentsActions.batchUploadToDarwin.failure): {
      const returnMessageFromDarwin: any = action.payload
      return {
        ...state,
        returnMessageFromDarwin,
      }
    }

    case getType(videoSegmentsActions.editVideoProperty.success): {
      const videoId: string = get(action.payload, ['data', 'videoId'], '')
      const data: Partial<VideoPropertyPayload> = get(
        action.payload,
        ['data', 'data'],
        [],
      )
      const batch: string = get(action.payload, ['data', 'batch'], '')
      const newVideos = state['batchDict'][batch]['videos']
      const newVideosArray = []
      for (const video of newVideos) {
        if (video._id === videoId) {
          const newVideoObj = {
            ...video,
            ...data,
          }
          newVideosArray.push(newVideoObj)
        } else {
          const newVideoObj = { ...video }
          newVideosArray.push(newVideoObj)
        }
      }
      return {
        ...state,
        batchDict: {
          ...state['batchDict'],
          [batch]: {
            ...state['batchDict'][batch],
            videos: newVideosArray,
          },
        },
        filteredVideoList: {
          ...state.filteredVideoList,
          [videoId]: {
            ...state.filteredVideoList[videoId],
            ...data,
          },
        },
      }
    }

    case getType(videoSegmentsActions.editVideoTag.success): {
      const videoId: string = get(action.payload, ['data', 'videoId'], '')
      const data: Partial<VideoPropertyPayload> = get(
        action.payload,
        ['data', 'data'],
        [],
      )
      const batch: string = get(action.payload, ['data', 'batch'], '')
      const newVideos = state['batchDict'][batch]['videos']

      const newVideosArray = []
      for (const video of newVideos) {
        if (video._id === videoId) {
          const newVideoObj = {
            ...video,
            ...data,
          }
          newVideosArray.push(newVideoObj)
        } else {
          const newVideoObj = { ...video }
          newVideosArray.push(newVideoObj)
        }
      }

      return {
        ...state,
        batchDict: {
          ...state['batchDict'],
          [batch]: {
            ...state['batchDict'][batch],
            videos: newVideosArray,
          },
        },
        filteredVideoList: {
          ...state.filteredVideoList,
          [videoId]: {
            ...state.filteredVideoList[videoId],
            ...data,
          },
        },
      }
    }

    case getType(videoSegmentsActions.getFilterFields.success): {
      const filterFields: FilterFields = action.payload
      return {
        ...state,
        filterFields,
      }
    }

    case getType(videoSegmentsActions.extractVideoFrames.success): {
      const videoId: string = action.payload.videoId
      const extracted = get(
        state.filteredVideoList,
        [videoId, 'extracted'],
        false,
      )
      if (extracted) {
        return state
      }
      return {
        ...state,
        filteredVideoList: {
          ...state.filteredVideoList,
          [videoId]: {
            ...state.filteredVideoList[videoId],
            extracted: true,
          },
        },
      }
    }

    case getType(videoSegmentsActions.bulkAddTags.success): {
      const videoIds: string[] = action.payload.videoIds
      const tags: string[] = action.payload.tags
      const classificationTags: string[] = action.payload.classificationTags

      const editedVideos = reduce(
        videoIds,
        (result, id) => {
          const oldTags = get(state.filteredVideoList, [id, 'tags'], [])
          const oldClassificationTags = get(
            state.filteredVideoList,
            [id, 'classificationTags'],
            [],
          )

          return {
            ...result,
            [id]: {
              ...state.filteredVideoList[id],
              tags: uniq([...oldTags, ...tags]),
              classificationTags: uniq([
                ...oldClassificationTags,
                ...classificationTags,
              ]),
            },
          }
        },
        {},
      )

      return {
        ...state,
        filteredVideoList: {
          ...state.filteredVideoList,
          ...editedVideos,
        },
      }
    }

    case getType(videoSegmentsActions.bulkAddRegion.success): {
      const videoIds: string[] = action.payload.videoIds
      const region: string = action.payload.region
      const editedVideos = reduce(
        videoIds,
        (result, id) => ({
          ...result,
          [id]: {
            ...state.filteredVideoList[id],
            region,
          },
        }),
        {},
      )

      return {
        ...state,
        filteredVideoList: {
          ...state.filteredVideoList,
          ...editedVideos,
        },
      }
    }

    default:
      return state
  }
}
